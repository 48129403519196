import styled from "styled-components"

export const Container = styled.div`
  padding-left: 20px;
`

export const sul = styled.ul`
  padding-left: 20px;
`

export const TopLine = styled.p`
  color: #0242cb;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #010606;

  @media screen and (max-width: 480px){
      font-size: 32px;
  }
`

export const Subtitle = styled.p`
   margin-bottom: 20px;
   font-size: 18px;
   line-height: 24px;
   color: #010606;
`