import styled from 'styled-components';
import {FaCaretLeft, FaCaretRight} from 'react-icons/fa'

export const SocialsContainer= styled.aside`
  position: fixed;
  z-index: 999;
  width: ${({isOpen}) => (isOpen ? '12rem' : '2rem')};
  height: 3rem;
  background: #0d0d0d;
  display: flex;
  align-items: center;
  top: 100px;
  right: 0;
  transition: 0.3s ease-in-out;
  opacity: 100%;
  border-radius: 10px 0 0 10px;
  justify-content: space-between;
  padding-right: 1rem;

  @media screen and (max-width: 768px){
    opacity: 0;
    right: -100%;
  }
`

export const Icon = styled.div`
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`

export const ExpandIcon = styled(FaCaretLeft)`
  color: #fff;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;

  &:hover{
    opacity: 1;
  }
`

export const ShrinkIcon = styled(FaCaretRight)`
  color: #fff;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;

  &:hover{
    opacity: 1;
  }
`

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  
`

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 1.5rem;
  opacity: 0.6;
  transition: opacity 0.2s ease-in-out;

  &:hover{
    opacity: 1;
  }
`
