import React, {useEffect, useState} from 'react'
import { GameContainer, GameWrapper, Single, Logo, InfoRow, TextWrapper, Column1, VideoWrap, Video, Column2, Heading, Subtitle, Button, ArrowForward, ArrowRight, SocialWrapper, FBIcon, TweetIcon } from './GameElements'
import AnimatedLogo from '../AnimatedLogo'
import ReactPlayer from 'react-player'
import useWindowDimensions from '../GetWindowDimensions'
import { BtnWrap } from '../GallerySection/GalleryElements'
import { FacebookShareButton, TwitterShareButton } from 'react-share'

const Game = ({ video, headline, description, gameUrl}) => {
    const width = useWindowDimensions().width;
    const [hover, setHover] = useState(false);
    const onHover = () => {
        setHover(!hover);
    }
    const [hover2, setHover2] = useState(false);
    const onHover2 = () => {
        setHover2(!hover2);
    }
    useEffect(() => {
        document.title = `${headline} | WizaGames`
    })
    return (
        <>
        <GameContainer>
            <Single>
                <AnimatedLogo />
                <Logo to='/' >WizaGames</Logo>
            </Single>
            <GameWrapper>
                <InfoRow>
                    <Column1 id='column1'>
                        <VideoWrap>
                            <Video>
                                <ReactPlayer url={video} controls width={width>720 ? '100%' : '80vw'} height='50vh' />
                            </Video>
                        </VideoWrap>
                    </Column1>
                    <Column2>
                        <TextWrapper>
                            <Heading lightText={false}>{headline}</Heading>
                            <Subtitle>{description}</Subtitle>
                            <BtnWrap>
                                <Button href={gameUrl} target='_blank' onMouseEnter={onHover}
                                    onMouseLeave={onHover}>Download {hover ? <ArrowForward /> : <ArrowRight />}</Button>
                                    <Button href={'/'} onMouseEnter={onHover2}
                                    onMouseLeave={onHover2}>Go back {hover2 ? <ArrowForward /> : <ArrowRight />}</Button>
                        </BtnWrap>
                        </TextWrapper>
                        <SocialWrapper>
                        <centeredText>Share this:&nbsp;&nbsp;&nbsp;</centeredText>
                        <FacebookShareButton quote={'Check out this cool mobile game I found called ' + headline + '!' } url={video} children=''><FBIcon size={32} round={true}/></FacebookShareButton>
                        <TwitterShareButton via={'_WZTK'} title={'Check out this cool mobile game I found called ' + headline + '!' } url={video} children=''><TweetIcon size={32} round={true}/></TwitterShareButton>
                        </SocialWrapper>
                    </Column2>
                </InfoRow>
            </GameWrapper>
        </GameContainer>
        </>
    )
}

export default Game
