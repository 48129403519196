import React from 'react'
import { Container } from './PrivacyElements'
import { MetaTags } from 'react-meta-tags'
const Privacy = ({ text }) => {
    return (
        <>
            <MetaTags>
                <meta name="robots" content='noindex'></meta>
            </MetaTags>
            <Container>
                {text}
            </Container>
        </>
    )
}

export default Privacy
