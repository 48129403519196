import React, {useState, useEffect} from 'react'
import {FaBars} from 'react-icons/fa'
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavSingle } from './NavbarElements'
import AnimatedLogo from '../AnimatedLogo.js'
import { animateScroll as scroll } from 'react-scroll'

const Navbar = ({toggle}) => {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 80){
            setScrollNav(true);
        }
        else{
            setScrollNav(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop();
    }
    return (
        <>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavSingle>
                    <AnimatedLogo/>
                    <NavLogo to='/' onClick={toggleHome}>WizaGames</NavLogo>
                    </NavSingle>
                    <MobileIcon onClick = {toggle}>
                        <FaBars/>
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to="about"
                            smooth={true} duration={500} spy={true} 
                            offset={-80}>About</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="games"
                            smooth={true} duration={500} spy={true} 
                            offset={-80}>Games</NavLinks>
                        </NavItem>
                        <NavItem>
                            {/* -70 offset on contact to avoid scroll bugging out */}
                            <NavLinks to="contact"
                            smooth={true} duration={500} spy={true} 
                            offset={-70}>Contact</NavLinks>
                        </NavItem>
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </>
    )
}

export default Navbar
