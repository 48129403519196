import React from 'react'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, ImgWrap, Img } from './InfoElements'

const InfoSection = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, img, alt}) => {
    return (
        <>
         <InfoContainer lightBg={lightBg} id={id}>
             <InfoWrapper imgStart={imgStart}>
                 <InfoWrapper>
                     <InfoRow imgStart={imgStart}>
                         <Column1>
                         <TextWrapper>
                             <TopLine>{topLine}</TopLine>
                             <Heading lightText={lightText}>{headline}</Heading>
                             <Subtitle extraPad={imgStart} darkText={darkText}>{description}</Subtitle>
                         </TextWrapper>
                         </Column1>
                         <Column2>
                         <ImgWrap>
                         <Img src={img} alt={alt}/>
                         </ImgWrap>
                         </Column2>
                     </InfoRow>
                 </InfoWrapper>
             </InfoWrapper>
         </InfoContainer>
        </>
    )
}

export default InfoSection
