export const homeObjOne = {
    id:'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'ABOUT WizaGames',
    headline: 'The reason things were created',
    description: "Ever since I was a small kid and got my hands on a computer, I started making things, simply because I loved seeing the result and people interacting with things I made. I've created lots of stuff - games, programs, videos, many of which didn't see the light of day. So, after saving some money, I decided to take some time off work and I started making mobile games and I hope to bring awesome experiences to people.",
    imgStart: false,
    img: require('../../assets/illustration1.svg').default,
    alt: 'gaming',
    darkText: false,
}

export const homeObjTwo = {
    id:'contact',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'CONTACT',
    headline: 'Get in touch',
    description: "Currently, the best way to get in touch for any business queries is to email me at wztkdeveloper@gmail.com. Alternatively, you can use any of the social platforms to send over the message and I will respond as soon as possible.\n\n\n\n\n\n",
    imgStart: true,
    img: require('../../assets/illustration2.svg').default,
    alt: 'e-mail',
    darkText: true,
}