import logo from '../assets/logo.png';
import anim from '../assets/logo.gif';
import React from 'react';
var GifPlayer = require('react-gif-player');

class AnimatedLogo extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            playing: false
        };
    }

    render(){
        return(
            <GifPlayer  width={90} height={80} gif={anim} still={logo} alt="WizaGames logo" onClick={e => this.state.playing ? e.stopPropagation() : ''} onTogglePlay={playing => {if(playing){setTimeout(() =>{this.pauseGif()}, 420);} this.setState({playing}) }} pauseRef={pause => this.pauseGif = pause}/>
        )
    }
}
export default AnimatedLogo