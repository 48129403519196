import React, { useState } from 'react';
import Video from '../../assets/video.mp4';
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroL, HeroThumbnail } from './HeroElements';
import thumbnail from '../../assets/thumbnail.jpg';

const HeroSection = () => {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    const onLoadedData = () => {
        setIsVideoLoaded(true);
    };

    const Letter = ({ letter }) => {
        const [clicked, setClicked] = useState(false);

        const onChange = () => {
            setClicked(true);
            setTimeout(() => {
                setClicked(false);
            }, 400);
        };

        const onMouseMove = (event) => {
            if (event.buttons === 1) {
                onChange();
            }
        };

        return (
            <HeroL onMouseDown={onChange} onMouseMove={onMouseMove} clicked={clicked}>
                {letter}
            </HeroL>
        );
    };

    return (
        <HeroContainer id="home">
            <HeroBg>
                <HeroThumbnail src={thumbnail} alt='background video thumbnail' isVideoLoaded={isVideoLoaded} />
                <VideoBg
                    autoPlay
                    loop
                    muted
                    src={Video}
                    isVideoLoaded={isVideoLoaded}
                    onContextMenu={e => e.preventDefault()}
                    onLoadedData={onLoadedData}
                    type='video/mp4'
                />
            </HeroBg>
            <HeroContent>
                <HeroH1>
                    <Letter letter="I" />
                    <Letter letter="n" />
                    <Letter letter="d" />
                    <Letter letter="i" />
                    <Letter letter="e " />
                    <Letter letter="g" />
                    <Letter letter="a" />
                    <Letter letter="m" />
                    <Letter letter="e" />
                    <Letter letter="s " />
                    <Letter letter="m" />
                    <Letter letter="a" />
                    <Letter letter="d" />
                    <Letter letter="e " />
                    <Letter letter="w" />
                    <Letter letter="i" />
                    <Letter letter="t" />
                    <Letter letter="h " />
                    <Letter letter="p" />
                    <Letter letter="a" />
                    <Letter letter="s" />
                    <Letter letter="s" />
                    <Letter letter="i" />
                    <Letter letter="o" />
                    <Letter letter="n" />
                </HeroH1>
                <HeroP>and relentless pursuit of excellence.</HeroP>
            </HeroContent>
        </HeroContainer>
    );
};

export default HeroSection;