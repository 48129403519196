import React from 'react'
import Game from '../components/Game'
import Footer from '../components/Footer'
import ScrollToTop from '../components/ScrollToTop'

const GamePage = ({game}) => {
    return (
        <>
        <ScrollToTop/>
        <Game {...game}/>
        <Footer/>
        </>
    )
}

export default GamePage
