import React from 'react';
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SocialIcons, SocialIconLink, SocialMedia, SocialMediaWrap } from './SidebarElements';
import { FaFacebook, FaYoutube, FaGooglePlay } from 'react-icons/fa'
const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen = {isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="about" 
                    smooth={true} duration={500} 
                    exact={'true'} offset={-80} onClick={toggle}>About</SidebarLink>
                    <SidebarLink to="games"
                    smooth={true} duration={500}
                    exact={'true'} offset={-80} onClick={toggle}>Games</SidebarLink>
                    {/* -70 offset on contact to avoid scroll bugging out */}
                    <SidebarLink to="contact"
                    smooth={true} duration={500} 
                    exact={'true'} offset={-70} onClick={toggle}>Contact</SidebarLink>
                    <SocialMedia>
                        <SocialMediaWrap>
                            <SocialIcons>
                                <SocialIconLink href="//www.youtube.com/channel/UCwKcMaan4ao1l4wp2sW9F9w/videos" target="_blank" rel="noreferrer"
                                    aria-label="Youtube">
                                    <FaYoutube />
                                </SocialIconLink>
                                <SocialIconLink href="//play.google.com/store/apps/dev?id=8990714811975786696" target="_blank" rel="noreferrer"
                                    aria-label="Google Play">
                                    <FaGooglePlay />
                                </SocialIconLink>
                                <SocialIconLink href="//www.facebook.com/WZTK-104767861902303" target="_blank" rel="noreferrer"
                                    aria-label="Facebook">
                                    <FaFacebook />
                                </SocialIconLink>
                            </SocialIcons>
                        </SocialMediaWrap>
                    </SocialMedia>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
