import './App.css';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom'
import Home from './pages';
import GamePage from './pages/game';
import { GalaxyMan, Hoops, MeOrThem, RhythmBlaster, TreasureRaiders } from './components/Game/Data';
import PrivacyPage from './pages/privacy';
import {GalaxyManPolicy, HoopsPolicy, MeOrThemPolicy, RhythmBlasterPolicy, TreasureRaidersPolicy} from './components/Privacy/Data'

function App() {
  return (
      <Router>
        <Routes>
          <Route path='/' element={<Home/>} exact/>
          <Route path='/game/hoops' element={<GamePage game={Hoops}/>} exact/>
          <Route path='/game/meorthem' element={<GamePage game={MeOrThem}/>} exact/>
          <Route path='/game/galaxyman' element={<GamePage game={GalaxyMan}/>} exact/>
          <Route path='/game/treasureraiders' element={<GamePage game={TreasureRaiders}/>} exact/>
          <Route path='/game/rhythmblaster' element={<GamePage game={RhythmBlaster}/>} exact/>
          <Route path='/sitemap.xml' exact/>
          <Route path='/game/hoops/policy' exact element={<PrivacyPage text={HoopsPolicy}/>}/>
          <Route path='/game/galaxyman/policy' exact element={<PrivacyPage text={GalaxyManPolicy}/>}/>
          <Route path='/game/treasureraiders/policy' exact element={<PrivacyPage text={TreasureRaidersPolicy}/>}/>
          <Route path='/game/rhythmblaster/policy' exact element={<PrivacyPage text={RhythmBlasterPolicy}/>}/>
          <Route path='/game/meorthem/policy' exact element={<PrivacyPage text={MeOrThemPolicy}/>}/>
          <Route path='*' element ={<Navigate to='/'/>}></Route>
        </Routes>
      </Router>
  );
}

export default App;
