import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md';
import {FacebookIcon, TwitterIcon} from 'react-share';

export const GameContainer = styled.div`
  color: #fff;
  background: linear-gradient(15deg, rgba(2,0,66,1) 0%, rgba(2,66,203,1) 65%, rgba(75,122,203,1) 100%);
  overflow:hidden;

`

export const GameWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 700px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: left;
  @media screen and (max-width:768px){
    height: max-content;
  }
  @media screen and (min-height: 700px){
    height: 100vh;
  }
`

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: 'col1 col2';

  @media screen and (max-width: 768px) {
      grid-template-areas: 'col1 col1' 'col2 col2'
  }
`

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
  width: 700px;

  @media screen and (max-width:1080px){
    width:500px;
  }
`

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
  
`

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 40px;
`

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #F06C33;

  @media screen and (max-width: 480px){
      font-size: 32px;
  }
`

export const Subtitle = styled.p`
   max-width: 440px;
   margin-bottom: 35px;
   font-size: 18px;
   line-height: 24px;
   color: ${({darkText}) => (darkText ? '#010606' : '#fff')};
`

export const VideoWrap = styled.div`
  max-width: 800px;
`

export const Video = styled.div`
  margin: 0 0 10px 0;
  padding-right: 0;
  overflow: hidden;

`

export const Single = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;
`;

export const Logo = styled(LinkR)`
  color: #fff;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
`;

export const Button = styled.a`
  border-radius: 50px;
  white-space: nowrap;
  background: ${'#33BD31'};
  padding: ${'12px 30px'};
  color: ${'#fff'};
  font-size: ${'16px'};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  margin-right: 16px;
  text-decoration: none;

  &:hover{
    transition: all 0.2s ease-in-out;
    background: ${'#41F03F'};
  }

  @media screen and (max-width:768px){
    margin-right: 8px;
    padding: ${'8px 20px'}
  }
`

export const BtnWrap = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`
export const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`
export const FBIcon = styled(FacebookIcon)`
  opacity: 0.8;
  transition: all 0.2s ease-in-out;

  &:hover{
    opacity: 1;
  }
`

export const TweetIcon = styled(TwitterIcon)`
  opacity: 0.8;
  transition: all 0.2s ease-in-out;

  &:hover{
    opacity: 1;
  }
`

export const Link = styled.a`
    color: #33BD31;
    :visited {
      color: #d20303;
    }

`