import React from 'react'
import { FooterContainer, FooterWrap, SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink } from './FooterElements'
import { FaFacebook, FaYoutube, FaGooglePlay} from 'react-icons/fa'
import { animateScroll as scroll } from 'react-scroll'
import { IconContext } from 'react-icons/lib'
const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    }
    return (
        <IconContext.Provider value = {{opacity: '0.6'}}>
        <FooterContainer>
            <FooterWrap>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo onClick={toggleHome} to='/'>WizaGames</SocialLogo>
                        <WebsiteRights>
                            © {new Date().getFullYear()} All rights reserved.
                        </WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="//www.youtube.com/channel/UCwKcMaan4ao1l4wp2sW9F9w/videos" target="_blank" rel="noreferrer"
                            aria-label="Youtube">
                                <FaYoutube/>
                            </SocialIconLink>
                            <SocialIconLink href="//play.google.com/store/apps/dev?id=8990714811975786696" target="_blank" rel="noreferrer"
                            aria-label="Google Play">
                                <FaGooglePlay/>
                            </SocialIconLink>
                            <SocialIconLink href="//www.facebook.com/WZTK-104767861902303" target="_blank" rel="noreferrer"
                            aria-label="Facebook">
                                <FaFacebook/>
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
        </IconContext.Provider>
    )
}

export default Footer
