import { Link } from "./GameElements"

export const Hoops= {
    id: 'Hoops',
    headline: 'Hoops',
    description: 'Start your own personal 3D basketball experience in seconds and feel total freedom in your approach! This is a skill-based 3D game, where scoring is challenging, but also satisfying and fun. ',
    video: 'https://youtu.be/14oNrSNJ-jk',
    gameUrl: '//play.google.com/store/apps/details?id=com.WZTK.Hoopz'
}

export const MeOrThem = {
    id: 'Me Or Them',
    headline: 'Me Or Them',
    description: "You are a survivor that escaped the city, which turned into a hell filled with Zombies. These monsters have taken everything away from you. You can't simply escape with them wandering around in the place you used to call home, so you decide to take action!",
    video: 'https://www.youtube.com/watch?v=_LIm7Guu9AE',
    gameUrl: '//play.google.com/store/apps/details?id=com.WZTK.MeOrThem'
}

export const GalaxyMan= {
    id: 'Galaxy Man',
    headline: 'Galaxy Man',
    description: "Galaxy man is about an astronaut sent into space with a jetpack. Help him travel across the galaxy, while avoiding the asteroids and space debris and collecting fuel to keep him going. As the adventure goes, it becomes increasingly difficult, but nothing our hero can't handle.",
    video: 'https://youtu.be/esuF4LYb730',
    gameUrl: '//play.google.com/store/apps/details?id=com.WZTK.GalaxyMan'
}

export const TreasureRaiders = {
    id: 'Treasure Raiders',
    headline: 'Treasure Raiders',
    description: ['You are a treasure hunter who got his hands on an epic chest full of gold and jewels, but other raiders have caught you in a tough spot! Your goal in this game is to defend this treasure for as long as possible from these bandits. Shoot your gun and defeat any raider that tries to come close and survive! This game has been mentioned in the article titled "Top Android Games To Tryout in November 2021", check it out ', <Link href="https://gameskeys.net/top-android-games-to-tryout-in-november-2021/#TreasureRaidersEpicBattle">here</Link>, '.'],
    video: 'https://youtu.be/ZhzUgz26WCM',
    gameUrl: '//play.google.com/store/apps/details?id=com.WZTK.TreasureRaiders'
}

export const RhythmBlaster = {
    id: 'Rhythm Blaster',
    headline: 'Rhythm Blaster',
    description: "Rhythm Blaster is a fast-paced, music-driven game that will test your reflexes and hand-eye coordination. Tap your way through the beats of the songs to see the notes explode before your eyes! Feel the rhythm of the music through handcrafted levels with beautiful, exciting, colorful and vibrant visuals that will inspire you to play more and perhaps even create your own level! This game will suit everybody - from casual to a competitive player of various skill levels and music taste, as it includes tracks with different difficulties and from multiple genres - rap, electronic, pop, lo-fi, rap, metal, rock.",
    video: 'https://youtu.be/9tNl3SsolIY',
    gameUrl: '//play.google.com/store/apps/details?id=com.WMS.RhythmBlaster'
}