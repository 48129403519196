import styled from "styled-components";

export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 800px;
  position: relative;
  z-index: 1;
`

export const HeroBg = styled.div`
  position:absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
  opacity: ${({isVideoLoaded}) => (isVideoLoaded ? 1 : 0)};
`

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const HeroH1 = styled.h1`
  color: ${({clicked}) => (clicked ? '#0424cb' : '#fff')};
  transition: all 0.2s ease-in-out;
  font-size: ${({clicked}) => (clicked ? '50px' : '48px')};
  text-align: center;
  text-shadow: 4px 4px 21px rgba(0,0,0,1);

  @media screen and (max-width: 768px){
      font-size: 40px;
  }

  @media screen and (max-width: 480px){
      font-size: 32px;
  }
`
export const HeroL = styled.span`
  color: ${({clicked}) => (clicked ? '#ffa500' : '#fff')};
  transition: all 0.2s ease-in-out;
  font-size: ${({clicked}) => (clicked ? '52px' : '48px')};
  /* TEXT UNSELECTABLE ATTRIBS */
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`

export const HeroP = styled.p`
  margin-top: 24px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  max-width: 600px;
  /* TEXT UNSELECTABLE ATTRIBS */  
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  text-shadow: 4px 4px 10px rgba(0,0,0,1);

  @media screen and (max-width: 768px){
      font-size: 21px;
  }

  @media screen and (max-width: 480px){
      font: 18px;
  }
`

export const HeroThumbnail = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
  opacity: ${({isVideoLoaded}) => (isVideoLoaded ? 0 : 1)};
  display: ${({isVideoLoaded}) => (isVideoLoaded ? 'none': '')};
  transition: all 0ms ease-in-out;
`
