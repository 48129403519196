import React from 'react'
import Privacy from '../components/Privacy'

const PrivacyPage = ({text}) => {
    return (
        <Privacy {...text} />
    )
}

export default PrivacyPage
