import React from 'react'
import { ExpandIcon, Icon, ShrinkIcon, SocialsContainer, SocialIconLink } from './SocialsElements'
import { FaFacebook, FaYoutube, FaGooglePlay } from 'react-icons/fa'

const SocialsBar = ({ isOpen, toggle }) => {

    return (
        <SocialsContainer isOpen={isOpen}>
            <Icon>
                {isOpen ? <ShrinkIcon onClick={toggle} /> : <ExpandIcon onClick={toggle} />}
            </Icon>
            <SocialIconLink href="//www.youtube.com/channel/UCwKcMaan4ao1l4wp2sW9F9w/videos" target="_blank" rel="noreferrer"
                aria-label="Youtube">
                <FaYoutube />
            </SocialIconLink>
            <SocialIconLink href="//play.google.com/store/apps/dev?id=8990714811975786696" target="_blank" rel="noreferrer"
                aria-label="Google Play">
                <FaGooglePlay />
            </SocialIconLink>
            <SocialIconLink href="//www.facebook.com/WZTK-104767861902303" target="_blank" rel="noreferrer"
                aria-label="Facebook">
                <FaFacebook />
            </SocialIconLink>
        </SocialsContainer>
    )
}

export default SocialsBar
