import React, {useState} from 'react'
// import Loadable from '@loadable/component'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import HeroSection from '../components/HeroSection'
import { homeObjOne, homeObjTwo } from '../components/InfoSection/Data'
import LazyLoad from 'react-lazyload'
import InfoSection from '../components/InfoSection' 
import GallerySection from '../components/GallerySection'
import Footer from '../components/Footer'
import SocialsBar from '../components/SocialsBar'

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    };

    const [socialOpen, setSocialOpen] = useState(false);

    const socialToggle = () => {
        setSocialOpen(!socialOpen)
    }

    return (
        <>
         <Sidebar isOpen={isOpen} toggle={toggle}/>
         <Navbar toggle={toggle}/>
         <SocialsBar isOpen={socialOpen} toggle={socialToggle}/>
         <HeroSection/>
         <LazyLoad offset={600} scroll={true} height={200}><InfoSection {...homeObjOne}/></LazyLoad>
         <LazyLoad offset={600} scroll={true} height={200}><GallerySection/></LazyLoad>
         <LazyLoad offset={800} scroll={true} height={200}><InfoSection {...homeObjTwo}/></LazyLoad>
         <LazyLoad offset={600} scroll={true} height={200}><Footer/></LazyLoad>
        </>
    )
}

export default Home
