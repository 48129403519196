import React, {useState} from 'react'
import SwiperCore, { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GalleryContainer, Img, SlideH1, SlideWrapper, SlideContent, SlideP, BtnWrap, Button, ArrowForward, ArrowRight } from './GalleryElements';
import photo1 from '../../assets/hoopz_screenshot2.png';
import photo2 from '../../assets/rhythmblasterss.png';
import photo3 from '../../assets/meorthem_screenshot.png';
import photo4 from '../../assets/galaxyman_screenshot.png';
import photo5 from '../../assets/treasureraiders_screenshot.png';
import photo1small from '../../assets/hoopz_screenshot2_small.png';
import photo2small from '../../assets/rhythmblasterss_small.png';
import photo3small from '../../assets/meorthem_screenshot_small.png';
import photo4small from '../../assets/galaxyman_screenshot_small.png';
import photo5small from '../../assets/treasureraiders_screenshot_small.png';

// Import Swiper styles
import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'
import useWindowDimensions from '../GetWindowDimensions';

SwiperCore.use([Navigation, Autoplay, Pagination]);
const GallerySection = () => {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }
    const width = useWindowDimensions().width;
    return (
        <GalleryContainer id="games">
        {/* <h1>CHECK OUT CURRENT RELEASES:</h1> */}
        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation
            autoplay ={{delay: 3000}}
            pagination={{clickable: false}}
            loop={true}
        >
            <SwiperSlide>
                    <SlideWrapper>
                    <Img src={width > 720 ? photo1 : photo1small} alt="Hoops screenshot"/>
                    <SlideContent>
                        <SlideH1>Hoops</SlideH1>
                        <SlideP>3D Basketball arcade game</SlideP>
                        <BtnWrap><Button to="/game/hoops" onMouseEnter={onHover}
                        onMouseLeave={onHover}>Game Page {hover ? <ArrowForward/> : <ArrowRight/>}</Button></BtnWrap>
                    </SlideContent>
                    </SlideWrapper>
            </SwiperSlide>
            <SwiperSlide>
                    <SlideWrapper>
                    <Img src={width > 720 ? photo2: photo2small} alt="Rhythm Blaster screenshot"/>
                    <SlideContent>
                        <SlideH1>Rhythm Blaster</SlideH1>
                        <SlideP>New kind of rhythm game (with level creator!)</SlideP>
                        <BtnWrap><Button to="/game/rhythmblaster" onMouseEnter={onHover}
                        onMouseLeave={onHover}>Game Page {hover ? <ArrowForward/> : <ArrowRight/>}</Button></BtnWrap>
                    </SlideContent>
                    </SlideWrapper>
            </SwiperSlide>
            <SwiperSlide>
                    <SlideWrapper>
                    <Img src={width > 720 ? photo3 : photo3small} alt="Me Or Them screenshot"/>
                    <SlideContent>
                        <SlideH1>Me Or Them</SlideH1>
                        <SlideP>Zombie shooter with RPG elements</SlideP>
                        <BtnWrap><Button to="/game/meorthem" onMouseEnter={onHover}
                        onMouseLeave={onHover}>Game Page {hover ? <ArrowForward/> : <ArrowRight/>}</Button></BtnWrap>
                    </SlideContent>
                    </SlideWrapper>
            </SwiperSlide>
            <SwiperSlide>
                    <SlideWrapper>
                    <Img src={width > 720 ? photo4 : photo4small} alt="Galaxy Man screenshot"/>
                    <SlideContent>
                        <SlideH1>Galaxy Man</SlideH1>
                        <SlideP>Endless runner starring an astronaut with jetpack</SlideP>
                        <BtnWrap><Button to="/game/galaxyman" onMouseEnter={onHover}
                        onMouseLeave={onHover}>Game Page {hover ? <ArrowForward/> : <ArrowRight/>}</Button></BtnWrap>
                    </SlideContent>
                    </SlideWrapper>
            </SwiperSlide>
            <SwiperSlide>
                    <SlideWrapper>
                    <Img src={width > 720 ? photo5 : photo5small} alt="Treasure Raiders screenshot"/>
                    <SlideContent>
                        <SlideH1>Treasure Raiders</SlideH1>
                        <SlideP>Top down shooter action game about defending your treasure</SlideP>
                        <BtnWrap><Button to="/game/treasureraiders" onMouseEnter={onHover}
                        onMouseLeave={onHover}>Game Page {hover ? <ArrowForward/> : <ArrowRight/>}</Button></BtnWrap>
                    </SlideContent>
                    </SlideWrapper>
            </SwiperSlide>

        </Swiper>
        </GalleryContainer>
    )
}

export default GallerySection
